import _, { delay } from 'lodash';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import { logout } from 'app/actions/authActions';
import { State } from 'app/reducers/state';
import { app } from 'app/store';

import React, {
  FunctionComponent, ReactElement, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import {
  ButtonProps, Loader, Segment, Sidebar, Button, Popup,
} from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

import { commitPreferences, stageUserPreferences } from 'app/actions/preferenceActions';
import { decodeBase64, generateId, isSidebarDisabled } from 'app/helpers/helpers';
import { StatusMessageDataModel } from './helpers/types';
import { createTabLinkState } from './actions/tabActions';
import Announcements from './components/Announcements/Announcements';
import { AnnouncementsShowOnLoginButton, AnnouncementsAckAllButton } from './components/Announcements/AnnouncementActionButtons';
import AnnouncementManagement from './components/AnnouncementsManagement/AnnouncementsManagement';
import AppVersion from './components/AppVersion/AppVersion';
import Diagnostics from './components/Help/Diagnostics';
import Help from './components/Help/Help';
import TokenInfo from './components/Help/TokenInfo';
import TokenViewer from './components/Help/TokenViewer';
import LinkStateShare from './components/LinkState/LinkStateShare';
import Navbar from './components/Navbar/Navbar';
import Preferences from './components/Preferences/Preferences';
import StatusModal, { StatusModalProps } from './components/shared/StatusModal/StatusModal';
import TabManager from './components/shared/TabManager/TabManager';
import SidebarContent from './components/SidebarContent/SidebarContent';
import Toolbox from './components/Toolbox/Toolbox';

import { TabMetadata, TabType } from './typings';
import { AnnouncementsShown } from './actions/announcementActions';

import SplitContainer, { SplitContainerContents } from './components/shared/SplitContainer/SplitContainer';
import PinnedTabManager from './components/shared/TabManager/PinnedTabManager';
import { getRequiredClaims } from './constants/config';
import JoyrideTour from './components/JoyrideTour/JoyrideTour';

import { provideGlobalGridOptions } from 'ag-grid-community';

type PinnedTabPanes = Record<keyof typeof TabType, SplitContainerContents[]>;

const App: FunctionComponent = () => {
  // Mark all grids as using legacy themes
  provideGlobalGridOptions({ theme: 'legacy' });

  const defaultPinnedTabs = {
    Company: [], System: [], Index: [], Partition: [], Key: [],
  };
  const dispatch = useDispatch();
  const tabState = useSelector((state: State) => state.tabs);
  const preferenceState = useSelector((state: State) => state.preferences);
  const tabSelection = useRef<TabMetadata[]>([]);
  const authState = useSelector((state: State) => state.auth);
  const announcementState = useSelector((state: State) => state.announcements);
  const unacknowledgedAnnouncements = _.filter(announcementState.data, (o) => o.hasBeenAcknowledged === false || o.hasBeenAcknowledged === undefined);
  const location = useLocation();
  const { showAnnouncements } = preferenceState.data;
  const initialModalProps: StatusModalProps = {
    icon: undefined,
    message: '',
    header: '',
    style: 'success',
    open: false,
    size: 'tiny',
    closeOnEscape: true,
    closeOnDimmerClick: true,
  };

  const [panes, setPanes] = useState<PinnedTabPanes>(defaultPinnedTabs);
  const [visible, setVisible] = useState(!preferenceState.data.hideSidebar);
  const [modalProps, setModalProps] = useState<StatusModalProps>(initialModalProps);
  const [startJoyride, setStartJoyride] = useState(false);
  const [userDropdown, setUserDropdown] = useState(false);

  const setModalInfoAndShow = (
    icon: SemanticICONS,
    message: ReactElement | string,
    header: string,
    style: 'error' | 'caution' | 'success' = 'success',
    actions: (ButtonProps | ReactElement)[] | undefined = undefined,
    size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen',
    closeOnEscape?: boolean,
    closeOnDimmerClick?: boolean,
  ) => {
    setModalProps({
      ...initialModalProps,
      icon,
      message,
      header,
      open: true,
      style,
      actions,
      size,
      closeOnEscape,
      closeOnDimmerClick,
    });
  };

  function showAnnouncementsModal(announcements: StatusMessageDataModel[]) {
    setModalInfoAndShow(
      'bullhorn',
      <Announcements announcements={announcements} closeModal={() => setModalProps({ ...modalProps, open: false })} />,
      'DSExplorer Announcements',
      'success',
      [
        <AnnouncementsShowOnLoginButton key="showOnLogin" />,
        <AnnouncementsAckAllButton key="ackAll" closeModal={() => setModalProps({ ...modalProps, open: false })} />,
        { content: 'Ok', color: 'blue' },
      ],
      'large',
      false,
      false,
    );
    dispatch(AnnouncementsShown());
  }

  useEffect(() => {
    // Modal should only pop once.
    if (announcementState.modalSeen || app.config.shouldDisableAnnouncements()) {
      return;
    }

    const sortedAnnouncements = _.sortBy(unacknowledgedAnnouncements, ['createdAt']);
    if (sortedAnnouncements.length) {
      // Only show if preference is enabled, otherwise show them as toasts.
      if (showAnnouncements) {
        showAnnouncementsModal(sortedAnnouncements);
      } else {
        Object.values(sortedAnnouncements).forEach((a) => {
          const title = (
            <>
              <p>{a.title}</p>
              <p className="toastSubtitle">(Click for details)</p>
            </>
          );
          const toastConfig = {
            pauseOnHover: true,
            toastId: generateId(),
            onClick: () => showAnnouncementsModal(sortedAnnouncements),
          };

          if (a.messageType === 2) {
            toast.error(title, toastConfig);
          } else if (a.messageType === 1) {
            toast.warning(title, toastConfig);
          } else {
            toast.info(title, toastConfig);
          }
        });
      }
    }
  }, [announcementState]);

  const hideModal = () => {
    setModalProps(initialModalProps);
  };

  useEffect(() => {
    if (!authState?.isLoggedIn()) {
      return;
    }

    if (location.search !== undefined) {
      const urlParams = new URLSearchParams(location.search);

      const themeSwitch = urlParams.get('themeSwitch');
      if (themeSwitch !== undefined && themeSwitch !== null) {
        const updated = { ...preferenceState.data, activeTheme: themeSwitch };
        dispatch(stageUserPreferences(updated));
        dispatch(commitPreferences(true));
      }
    }
  }, [location.search]);

  useEffect(() => {
    // If hidden via Preferences we don't need to check if it's currently disabled
    if (preferenceState.data.hideSidebar) {
      setVisible(false);
    } else {
      setVisible(!isSidebarDisabled(location.pathname));
    }

    // Change the active theme on the container div for the app
    const appElement = document.getElementById('app');
    if (appElement) {
      appElement.className = preferenceState.staged?.activeTheme ?? preferenceState.data.activeTheme;
    }
  }, [location.pathname, preferenceState]);

  const openHelp = () => setModalInfoAndShow('help', <Help />, 'Help / FAQ');
  const validClaims = getRequiredClaims();
  // Alert if wrong domain or missing a claim.
  useEffect(() => {
    if (!authState?.isLoggedIn()) {
      return;
    }

    // Skip this logic if claims warning is disabled
    if (app.config.disableClaimsWarning()) {
      return;
    }

    const userDomain = authState.UserIdentity?.Name?.split('@')[1];
    const instersectingClaims = _.intersectionWith(
      authState.UserIdentity?.Claims,
      validClaims,
      (first, second) => first.indexOf('claims/role') > -1 && first.endsWith(second),
    );

    if (userDomain !== undefined && !userDomain.toLowerCase().indexOf('me.gbl')) {
      setModalInfoAndShow(
        'user x',
        `You are logged in with ${userDomain} domain. 
        Only *ME domains are supported (such as AME). Please log out and back in with the correct domain.`,
        'Wrong Domain',
        'error',
        [
          { content: `Continue with ${userDomain}`, className: 'negative', icon: 'close' },
          {
            content: 'Logout', className: 'positive', icon: 'user x', onClick: () => dispatch(logout()),
          },
        ],
      );
    } else if (instersectingClaims.length === 0) {
      setModalInfoAndShow(
        'group',
        <>
          You are missing a required group membership to use this application.
          Please check Help for details on the group to join. Once you have joined the group, log out and back in.
          <br />
          <br />
          The list of valid reader claim(s) are:
          <ul>
            {validClaims.map((v) => (
              <li key={v}>
                {v}
              </li>
            ))}
          </ul>
        </>,
        'Missing Required Claim',
        'error',
        [
          { content: 'Continue Anyways', className: 'negative', icon: 'close' },
          {
            content: 'Logout', icon: 'user x', onClick: () => dispatch(logout()),
          },
          {
            // Open help with a short delay to give time for this modal to close first.
            content: 'Open Help', className: 'positive', icon: 'help', onClick: () => delay(openHelp, 100),
          },
        ],
      );
    }
  }, [authState]);

  useEffect(() => {
    const panesToCreate: PinnedTabPanes = defaultPinnedTabs;

    panesToCreate.Company.push({
      component: <TabManager tabType={TabType.Company} hidepagingcontrolswhendisabled="true" />,
      key: 'CompanyTabManager',
    });

    panesToCreate.System.push({
      component: <TabManager tabType={TabType.System} hidepagingcontrolswhendisabled="false" />,
      key: 'SystemTabManager',
    });

    panesToCreate.Partition.push({
      component: <TabManager tabType={TabType.Partition} hidepagingcontrolswhendisabled="false" />,
      key: 'PartitionTabManager',
    });

    panesToCreate.Index.push({
      component: <TabManager tabType={TabType.Index} hidepagingcontrolswhendisabled="false" />,
      key: 'IndexTabManager',
    });

    const pinnedTabs = _.groupBy(tabState?.pinned, (o) => o.metadata.tabType);
    _.keysIn(pinnedTabs).forEach((t) => {
      if (location.pathname.toLowerCase() === `/${t.toLowerCase()}`) {
        panesToCreate[t as TabType.Company | TabType.System].push({
          component: <PinnedTabManager tabType={t as TabType} />,
          key: `${t}PinnedTabManager`,
        });
      }
    });

    setPanes(panesToCreate);
  }, [tabState?.pinned, location]);

  if (!authState?.isLoggedIn()) {
    return (
      <Segment basic className="auth-loader">
        <Loader active size="big" inline="centered">
          Please wait for authentication redirect...
        </Loader>
      </Segment>
    );
  }

  const appVersion = app.config.getAppVersion();
  const buildDate = app.config.getBuildDate();

  const copyToken = () => {
    if (authState) {
      const xml = decodeBase64(authState.accessToken || '');
      navigator.clipboard.writeText(xml);
      toast.success('Token copied');
    }
  };

  return (
    <div className="App">
      <JoyrideTour startTour={startJoyride} endTour={() => setStartJoyride(false)} setUserDropdown={(value) => setUserDropdown(value)} />
      <ToastContainer style={{ marginTop: '45px' }} position="top-right" autoClose={4000} />
      <Navbar
        userDropdown={userDropdown}
        startTourOnClick={() => setStartJoyride(true)}
        announcements={announcementState.data}
        // eslint-disable-next-line
        announcementsOnClick={() => showAnnouncementsModal(announcementState.data)}
        sideBarVisible={visible}
        showClock={preferenceState.data.timeUtc}
        toggleSidebar={() => setVisible(!visible)}
        deepLinkOnClick={() => setModalInfoAndShow(
          'external alternate',
          <LinkStateShare setOnLinkGenerateCallback={(data) => {
            tabSelection.current = data;
          }}
          />,
          'DSExplorer Short Link',
          'success',
          [
            {
              content: 'Generate New Link',
              icon: 'external alternate',
              color: 'blue',
              onClick: (e) => { dispatch(createTabLinkState(tabSelection.current)); e.defaultPrevented = true; },
            },
            {
              content: 'Okay', className: 'positive',
            },
          ],
        )}
        preferencesOnClick={() => setModalInfoAndShow('settings', <Preferences />, 'User Preferences', 'success', undefined, 'small')}
        helpOnClick={openHelp}
        infoOnClick={() => setModalInfoAndShow('info', <AppVersion appVersion={appVersion} buildDate={buildDate} />, 'Application Info')}
        tokenInfoOnClick={() => setModalInfoAndShow('drivers license', <TokenInfo />, 'DSTS Token Info', 'success', undefined, 'small')}
        tokenViewerOnClick={() => setModalInfoAndShow(
          'shield alternate',
          <TokenViewer />,
          'Token Viewer',
          'success',
          authState?.UserIdentity?.IsPlatformServiceAdministrator ? [
            {
              content: 'Copy',
              icon: 'copy',
              color: 'blue',
              onClick: (e) => { copyToken(); e.defaultPrevented = true; },
            },
            {
              content: 'Okay',
            },
          ] : undefined,
          'large',
        )}
        diagnosticsOnClick={() => setModalInfoAndShow('setting', <Diagnostics />, 'Diagnostics', 'success', undefined, 'large')}
      />

      <Sidebar.Pushable as={Segment}>
        {!isSidebarDisabled(location.pathname) ? (
          <Sidebar
            animation="push"
            inverted="true"
            className="extremely thin"
            visible={!visible}
          >
            <Button
              className="toggle-button toggle-right"
              compact
              primary
              icon="chevron right"
              size="large"
              onClick={() => setVisible(!visible)}
            />
          </Sidebar>
        ) : null}
        {!isSidebarDisabled(location.pathname) ? (
          <Sidebar
            animation="push"
            inverted="true"
            width="wide"
            visible={visible}
          >
            <Popup
              flowing
              position="right center"
              content="Collapse Sidebar"
              trigger={(
                <Button
                  className="toggle-button"
                  compact
                  primary
                  icon="chevron left"
                  size="large"
                  onClick={() => setVisible(!visible)}
                />
              )}
            />

            <SidebarContent />
          </Sidebar>
        ) : null}

        <Sidebar.Pusher>
          <Segment basic className="main-pusher-area">
            <Switch>
              <Route
                path="/company"
                render={() => <SplitContainer Contents={panes.Company} />}
              />
              <Route
                path="/system"
                render={() => <SplitContainer Contents={panes.System} />}
              />
              <Route
                path="/index"
                render={() => <SplitContainer Contents={panes.Index} />}
              />
              <Route
                path="/partition"
                render={() => <SplitContainer Contents={panes.Partition} />}
              />
              <Route path="/toolbox" component={Toolbox} />
              <Route
                path="/key"
                render={() => <TabManager tabType={TabType.Key} hidepagingcontrolswhendisabled="true" />}
              />
              {
                authState?.UserIdentity?.IsPlatformServiceAdministrator
                  ? (
                    <Route
                      path="/announcementManagement"
                      render={() => <AnnouncementManagement />}
                    />
                  ) : null
              }
              <Redirect path="*" to="/company" />
            </Switch>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      <StatusModal
        size={modalProps.size}
        header={modalProps.header}
        message={modalProps.message}
        style={modalProps.style}
        open={modalProps.open}
        icon={modalProps.icon}
        closeEvent={hideModal}
        actions={modalProps.actions}
        closeOnEscape={modalProps.closeOnEscape}
        closeOnDimmerClick={modalProps.closeOnDimmerClick}
      />
    </div>
  );
};

export default App;
